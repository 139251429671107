import { Box, Text } from "@chakra-ui/react";

export const ZoomBoxMessage = () => {
  return (
    <Box fontWeight="normal">
      <Text textAlign="center">
        <strong>Segure</strong> e <strong>arraste</strong> no mapa para dar zoom
      </Text>
    </Box>
  );
};
