import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import * as esri from "esri-leaflet";
import L from "leaflet";
import "../renderer/EsriLeafletRenderers";
import EsriLeafletCluster from "esri-leaflet-cluster";

L.esri = esri;

type AddLayerProps = {
  id: string;
  map: L.Map;
  url: string;
  pane: string;
  zIndex: number;
  opacity: number;
};

export const addEsriService = ({
  id,
  map,
  url,
  opacity,
  // pane,
  zIndex
}: AddLayerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const layerAdded: any = EsriLeafletCluster({
    url: url,
    disableClusteringAtZoom: 8,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onEachFeature: function (feature: any) {
      // if (
      //   feature.geometry.type !== "Point" &&
      //   feature.geometry.type !== "MultiPoint"
      // ) {
      layerAdded.resetStyle(feature.id);
      // }
    },
    style: function () {
      return { opacity, zIndex };
    }
    // renderer: L.canvas(),
  });

  layerAdded.addTo(map);

  // layerAdded.bindPopup(function (layer: any) {
  //   console.log(layer.feature.properties);
  //   const util: any = L.Util;
  //   return util.template("Magnitude <strong>ae</strong> earthquake");
  // });

  layerAdded.id = id;
};
