/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TipoServicoCamada } from "../../../../enums/tipoServicoCamada";

interface CamadaRequest {
  id: string;
  idGrupo: string;
  nome: string;
  tipoServico: TipoServicoCamada;
  url: string;
  opacity: number;
  zIndex: number;
}

export interface Camada {
  id: string;
  idGrupo: string;
  nome: string;
  url: string;
  tipoServico: TipoServicoCamada;
  mostrarLegenda: boolean;
  opacity: number;
  zIndex: number;
}

interface State {
  camadas: Camada[];
}

const initialState: State = {
  camadas: []
};

const selecionarCamadaSlice = createSlice({
  name: "mapa.camada@selecionarCamada",
  initialState,
  reducers: {
    selecionarCamada: (state, action: PayloadAction<CamadaRequest>): void => {
      if (!state.camadas.some((x) => x.id == action.payload.id)) {
        state.camadas.push({ ...action.payload, mostrarLegenda: false });
      }
    },
    removerCamada: (state, action: PayloadAction<{ id: string }>): void => {
      state.camadas = state.camadas.filter((x) => x.id != action.payload.id);
    },
    mudarVisualizacaoLegenda: (
      state,
      action: PayloadAction<{ id: string }>
    ): void => {
      const camada = state.camadas.find((x) => x.id == action.payload.id);
      if (camada) {
        camada.mostrarLegenda = !camada.mostrarLegenda;
      }
    },
    mudarOpacity: (
      state,
      action: PayloadAction<{ id: string; opacity: number }>
    ): void => {
      const camada = state.camadas.find((x) => x.id == action.payload.id);
      if (camada) {
        camada.opacity = action.payload.opacity;
      }
    },
    limparCamadas: (state): void => {
      state.camadas = [];
    }
  }
});

export const {
  selecionarCamada,
  removerCamada,
  limparCamadas,
  mudarVisualizacaoLegenda
} = selecionarCamadaSlice.actions;
export default selecionarCamadaSlice.reducer;
