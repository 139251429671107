import React from "react";
import { useNavigate } from "react-router-dom";
import { SignoutCallbackComponent } from "redux-oidc";
import { userManager } from "../../../_config/userManager";

export const CallbackSignout: React.FC = () => {
  const navigate = useNavigate();
  return (
    <SignoutCallbackComponent
      userManager={userManager}
      successCallback={() => {
        navigate("/");
      }}
      errorCallback={() => {
        navigate("/");
      }}
    >
      <div></div>
    </SignoutCallbackComponent>
  );
};
