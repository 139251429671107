/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BBox } from "geojson";
import { LatLngBoundsLiteral } from "leaflet";
import { ToLatLngBound } from "../../../../utils/map/converters/BoundConverter";

interface Tema {
  nome: string;
  paleta: { [x: number]: string };
}

export interface ObterConfiguracaoResponse {
  corTema: Tema;
  bbox: BBox;
  bboxLatLng: LatLngBoundsLiteral;
}

interface State {
  isRequesting: boolean;
  successPayload: ObterConfiguracaoResponse | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: null,
  errors: []
};

const obterConfiguracaoSlice = createSlice({
  name: "tema@obterConfiguracao",
  initialState,
  reducers: {
    obterConfiguracao: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterConfiguracaoSuccess: (
      state,
      action: PayloadAction<ObterConfiguracaoResponse>
    ): void => {
      state.isRequesting = false;
      const result = action.payload;

      if (result.bbox && result.bbox.length >= 2) {
        const bbox = result.bbox;
        result.bboxLatLng = ToLatLngBound(bbox);
      }

      state.successPayload = result;
    },
    obterConfiguracaoFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

export const {
  obterConfiguracao,
  obterConfiguracaoSuccess,
  obterConfiguracaoFailed
} = obterConfiguracaoSlice.actions;
export default obterConfiguracaoSlice.reducer;
