/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import axios from "axios";
import { AnyAction, Store } from "redux";

const api = axios.create({ baseURL: `${process.env.REACT_APP_API}` });

let store: Store<any, AnyAction>;

export const injectStore = (_store: Store<any, AnyAction>) => {
  store = _store;
};

api.interceptors.request.use(async (config) => {
  const userData = store.getState().oidc.user;

  if (!userData) {
    return config;
  }

  const { access_token } = userData;
  if (access_token) {
    config.headers = {
      ...config.headers,
      Authorization: `bearer ${access_token}`,
      "Content-Type": "application/json"
    };
  }

  return config;
});

export default api;
