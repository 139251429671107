import { Box, HStack, Link, Tooltip } from "@chakra-ui/react";
import Pannellum from "pannellum-react/es/elements/Pannellum";
import { useRef } from "react";
import {
  RiCloseLine,
  RiFullscreenExitLine,
  RiFullscreenLine
} from "react-icons/ri";
import { useSearchParams } from "react-router-dom";

type Props = {
  id: string | undefined;
  onChangeYaw: (yaw: number) => void;
  onChangePitch: (pitch: number) => void;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  onClose: () => void;
};

export const ImageViewer = ({
  id,
  onChangeYaw,
  onChangePitch,
  isFullScreen,
  toggleFullScreen,
  onClose
}: Props) => {
  const [searchParams] = useSearchParams();
  const initialYaw = searchParams.get("initial_yaw");
  const maxPitch = 50;
  const minPitch = -50;
  const initialPitch = searchParams.get("initial_pitch");
  const isInitialPitchBetweenRange =
    initialPitch &&
    Number(initialPitch) >= minPitch &&
    Number(initialPitch) <= maxPitch;

  const panRef = useRef();

  if (!id) return null;

  return (
    <Box width="100%" height="100%" position="relative">
      <HStack position="absolute" top="2" right="2" zIndex={2} color="white">
        <Tooltip
          label={isFullScreen ? "Sair de janela cheia" : "Janela cheia"}
          placement="top"
        >
          <Link
            onClick={toggleFullScreen}
            bgColor="rgba(0,0,0,0.4)"
            p="1"
            borderRadius="md"
          >
            {isFullScreen ? <RiFullscreenExitLine /> : <RiFullscreenLine />}
          </Link>
        </Tooltip>
        <Tooltip label="Fechar" placement="top">
          <Link
            onClick={onClose}
            bgColor="rgba(0,0,0,0.4)"
            p="1"
            borderRadius="md"
          >
            <RiCloseLine />
          </Link>
        </Tooltip>
      </HStack>
      <Pannellum
        ref={panRef}
        width="100%"
        height="100%"
        image={`${process.env.REACT_APP_API}api/v1/panorama/${id}/foto`}
        pitch={isInitialPitchBetweenRange ? Number(initialPitch) : 10}
        maxPitch={maxPitch}
        minPitch={minPitch}
        yaw={initialYaw ? Number(initialYaw) : 0}
        hfov={110}
        maxHfov={140}
        autoLoad
        showControls={false}
        compass={false}
        orientationOnByDefault
        onRender={() => {
          if (panRef && panRef.current) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const pannellum = panRef.current as any;
            const yaw = pannellum.getViewer().getYaw();
            const pitch = pannellum.getViewer().getPitch();
            onChangeYaw(yaw);
            onChangePitch(pitch);
          }
        }}
      ></Pannellum>
    </Box>
  );
};
