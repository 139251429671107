import L from "leaflet";
import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right"
};

type Props = {
  position: "bottomleft" | "bottomright" | "topleft" | "topright";
  children: React.ReactNode;
};

export const ControlPortalContent = ({
  position,
  children
}: Props): JSX.Element => {
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  const targetDiv = document.getElementsByClassName(positionClass);

  const div = targetDiv[0] as HTMLElement;
  const portalContainer = document.createElement("div");

  useEffect(() => {
    if (div) {
      div.prepend(portalContainer);
    } else {
      const containerControls = document.getElementsByClassName(
        "leaflet-control-container"
      )[0];
      const newPositionContainer = document.createElement("div");
      newPositionContainer.classList.add(positionClass);
      newPositionContainer.prepend(portalContainer);
      containerControls.prepend(newPositionContainer);
    }
  }, []);

  useEffect(
    () => () => {
      div.removeChild(portalContainer);
    },
    []
  );

  const controlContainer = children;
  L.DomEvent.disableClickPropagation(portalContainer);

  return ReactDOM.createPortal(controlContainer, portalContainer);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function propsAreEqual(prev: any, next: any) {
  return prev.position === next.position;
}

export const ControlPortal = React.memo(ControlPortalContent, propsAreEqual);
