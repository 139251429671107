/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { GeoJsonProperties, FeatureCollection, Geometry } from "geojson";

import api from "../../../../../_config/api";
import { formatError } from "../../../../../utils/errorHelper";

interface State {
  isRequesting: boolean;
  requestNumber: string;
  successPayload: FeatureCollection | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: null,
  requestNumber: String(Math.random()),
  errors: []
};

const obterGeometriaFotos360Slice = createSlice({
  name: "mapa.panorama@obterGeometriaFotos360",
  initialState,
  reducers: {
    obterGeometriaFotos360: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterGeometriaFotos360Success: (
      state,
      action: PayloadAction<FeatureCollection<Geometry, GeoJsonProperties>>
    ): void => {
      state.isRequesting = false;
      state.requestNumber = String(Math.random());
      state.successPayload = action.payload;
    },
    obterGeometriaFotos360Failed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    },
    reset: (state): void => {
      state.isRequesting = false;
      state.successPayload = null;
    }
  }
});

function* sagaFunction() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/panorama/geo`
    );

    const { data } = response;

    yield put(obterGeometriaFotos360Success(data));
  } catch (error) {
    yield put(obterGeometriaFotos360Failed(formatError(error)));
  }
}

export const {
  obterGeometriaFotos360,
  obterGeometriaFotos360Success,
  obterGeometriaFotos360Failed,
  reset
} = obterGeometriaFotos360Slice.actions;
export const { reducer } = obterGeometriaFotos360Slice;
export const saga = takeLatest(obterGeometriaFotos360.type, sagaFunction);
