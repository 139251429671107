/* eslint-disable @typescript-eslint/no-explicit-any */
import L, { ControlPosition } from "leaflet";
import "leaflet-measure/dist/leaflet-measure.css";
import "leaflet-measure/dist/leaflet-measure.pt_BR";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "./styles.css";

type Props = {
  position: ControlPosition;
};

export const MeasureMapControl = ({ position }: Props) => {
  const map = useMap();

  useEffect(() => {
    if (map) {
      const leaf: any = L.Control;
      const measureControl = new leaf.Measure({
        position: position,
        activeColor: "blue",
        completedColor: "blue",
        primaryLengthUnit: "meters",
        secondaryLengthUnit: "kilometers",
        primaryAreaUnit: "sqmeters",
        secondaryAreaUnit: undefined
      });
      measureControl.addTo(map);
    }
  }, []);
  return null;
};
