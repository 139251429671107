/* eslint-disable @typescript-eslint/no-explicit-any */
import { compose, applyMiddleware, StoreEnhancer } from "redux";
import { configureStore } from "@reduxjs/toolkit";

declare global {
  interface Console {
    tron: any;
  }
}

export default (reducers: any, middlewares: any) => {
  const enhancer: StoreEnhancer =
    process.env.NODE_ENV === "development"
      ? compose(console.tron.createEnhancer())
      : applyMiddleware(...middlewares);

  return configureStore({
    middleware: [...middlewares],
    enhancers: process.env.NODE_ENV === "development" ? [enhancer] : [],
    reducer: reducers
  });
};
