import {
  Box,
  Circle,
  Collapse,
  Flex,
  Icon,
  Text,
  useBoolean,
  useColorModeValue
} from "@chakra-ui/react";
import { RiArrowDownSLine, RiArrowUpSLine, RiStackLine } from "react-icons/ri";
import { TipoServicoCamada } from "../../../../../../../store/enums/tipoServicoCamada";
import { useTypedSelector } from "../../../../../../../store/hooks";
import { TextGray } from "../../../../../../Atomos/Texts/TextGray";
import { Layer } from "./Layer";

type Camada = {
  idCamada: string;
  nome: string;
  url: string;
  ativo: boolean;
  publico: boolean;
  ordem: number;
  tipoServico: {
    value: TipoServicoCamada;
    name: string;
  };
};

type Props = {
  idGrupo: string;
  nome: string;
  ordem: number;
  camadas: Camada[];
};

export const GroupLayer = ({ idGrupo, nome, ordem, camadas }: Props) => {
  const hoverBgColor = useColorModeValue("rgba(0,0,0,0.05)", "rgba(0,0,0,0.4)");
  const layersSelecionadas = useTypedSelector(
    (state) => state.mapa.camada.selecionarCamada
  );
  const [flag, setFlag] = useBoolean();

  const qtdCamadas = camadas.length;
  const hasActive = layersSelecionadas.camadas.some(
    (x) => x.idGrupo == idGrupo
  );

  return (
    <Box w="full">
      <Flex
        as="button"
        w="full"
        py={2}
        px={3}
        _hover={{ bgColor: hoverBgColor }}
        borderRadius="md"
        alignItems="center"
        justifyContent="space-between"
        onClick={setFlag.toggle}
      >
        <Flex alignItems="center">
          <Icon as={RiStackLine} fontSize="md" color="gray.500" mr="2" />
          <Text fontSize="sm">{nome}</Text>
        </Flex>
        <Flex alignItems="center">
          {hasActive && <Circle size="7px" bg="main.400" mr={1} />}
          <TextGray fontSize="xs" mr="1">
            ({qtdCamadas})
          </TextGray>

          <Icon
            as={flag ? RiArrowUpSLine : RiArrowDownSLine}
            fontSize="md"
            color="gray.500"
          />
        </Flex>
      </Flex>
      <Collapse in={flag} animateOpacity>
        <Flex direction="column">
          {camadas.map((c) => (
            <Layer
              key={c.idCamada}
              idGrupo={idGrupo}
              ordemGrupo={ordem}
              camada={c}
            />
          ))}
        </Flex>
      </Collapse>
    </Box>
  );
};
