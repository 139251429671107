/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Link, Text } from "@chakra-ui/react";
import L from "leaflet";
import { useEffect, useState } from "react";
import { useMapEvents } from "react-leaflet";
import { TextGray } from "../../../../Atomos/Texts/TextGray";
import Marker from "../../../../../_assets/imgs/marker1.svg";
import { usePopupNotification } from "../../context/PopupNotificationProvider";

const ClickPointControlIcon = new L.Icon({
  iconUrl: Marker,
  iconSize: [20, 20]
});
let markerClickPointControl: L.Marker<any> | null = null;

export const ClickPointControl = () => {
  const [isActive, setIsActive] = useState(false);
  const { open, close } = usePopupNotification();

  const removeMarker = () => {
    markerClickPointControl?.remove();
    markerClickPointControl = null;
  };

  const map = useMapEvents({
    click: (e) => {
      if (!isActive) return;
      removeMarker();

      markerClickPointControl = L.marker([e.latlng.lat, e.latlng.lng], {
        icon: ClickPointControlIcon
      }).addTo(map);

      open(<MessageClick lat={e.latlng.lat} lng={e.latlng.lng} />);
    }
  });

  const onClick = () => {
    setIsActive((state) => !state);
    removeMarker();
    close();
  };

  useEffect(() => {
    if (isActive) {
      open(
        <Text>
          <strong>Clique</strong> sobre o mapa para ver informações do ponto.
        </Text>
      );
    }
  }, [isActive]);

  return (
    <div
      className={`leaflet-control leaflet-bar ${isActive && "button-enabled"}`}
    >
      <a onClick={onClick} title="Ver ponto mapa">
        <i className="fas fa-map-pin"></i>
      </a>
    </div>
  );
};

type MessageClickProps = {
  lat: number;
  lng: number;
};

const MessageClick = ({ lat, lng }: MessageClickProps) => {
  return (
    <Box px={3} py={1}>
      <Text fontWeight="medium">Coordenadas</Text>
      <TextGray fontSize="sm" mb="2" userSelect="text">
        {lat.toFixed(7)}, {lng.toFixed(7)}
      </TextGray>
      <Button
        as={Link}
        variant="link"
        fontSize="sm"
        href={`https://www.google.com.br/maps?q=${lat},${lng}`}
        target="_blank"
        w="full"
      >
        Ver no google maps
      </Button>
    </Box>
  );
};
