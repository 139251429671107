import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { CallbackComponent } from "redux-oidc";
import { TextGray } from "../../../components/Atomos/Texts/TextGray";
import { userManager } from "../../../_config/userManager";

export const CallbackPage: React.FC = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={(user) => {
        if (user && user.state && user.state.from) {
          navigate(user.state.from.pathname);
        } else {
          navigate("/");
        }
      }}
      errorCallback={() => {
        setError(true);
      }}
    >
      {error ? (
        <Box textAlign="center" py={10} px={6}>
          <Box display="inline-block">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg={"red.500"}
              fontSize="xx-large"
              rounded={"50px"}
              w={"55px"}
              h={"55px"}
              textAlign="center"
            >
              <RiCloseLine />
            </Flex>
          </Box>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            Erro ao logar
          </Heading>
          <TextGray mb={10}>
            Não foi possivel realizar o login. Tente novamente mais tarde.
          </TextGray>
          <Button onClick={() => navigate("/")} variant="outline">
            Tentar novamente
          </Button>
        </Box>
      ) : (
        <div />
      )}
    </CallbackComponent>
  );
};
