import { createUserManager } from "redux-oidc";

const userManagerConfig = {
  client_id: process.env.REACT_APP_CLIENTID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  redirect_uri: process.env.REACT_APP_REDIRECTURI,
  response_type: "token id_token",
  scope: process.env.REACT_APP_SCOPES,
  post_logout_redirect_uri: process.env.REACT_APP_POSTLOGOUTREDIRECT,
  authority: process.env.REACT_APP_AUTHORITY,
  revokeAccessTokenOnSignout: true,
  monitorSession: false,
  loadUserInfo: false
};

export const userManager = createUserManager(userManagerConfig);
