import { Link, Box } from "@chakra-ui/react";

import { RiArrowUpSLine } from "react-icons/ri";

type PontosVizinhos = {
  id: string;
  angulo: number;
};

type Props = {
  vizinhos: PontosVizinhos[];
  rotation: number;
  isFullScreen: boolean;
  onClick: (id: string) => void;
};

export const ArrowDirections = ({
  vizinhos,
  rotation,
  isFullScreen,
  onClick
}: Props) => {
  const rotationZ = rotation * -1;
  const scale = isFullScreen ? 1 : 0.5;
  const defaultPositionBottom = isFullScreen ? 0 : "-60px";

  return (
    <Box
      position="absolute"
      w="50px"
      h="160px"
      bottom={defaultPositionBottom}
      left="50%"
      transform={`translateX(-50%) translateY(-50%) rotateZ(${rotationZ}deg) scale(${scale})`}
      zIndex={2}
      color="white"
      fontSize="7rem"
    >
      {vizinhos.map((x) => (
        <Link
          key={x.id}
          position="absolute"
          transformOrigin="50% 140%"
          transform={`translateX(-50%) rotateZ(${x.angulo}deg)`}
          top="-50%"
          left="50%"
          zIndex={2}
          color="white"
          fontSize="7rem"
          onClick={() => onClick(x.id)}
          borderRadius="full"
          _hover={{ bgColor: "rgba(0, 0, 0, 0.2)" }}
        >
          <RiArrowUpSLine />
        </Link>
      ))}
    </Box>
  );
};
