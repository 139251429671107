import { Store } from "redux";
import { loadUser } from "redux-oidc";
import createSagaMiddleware from "redux-saga";
import { injectStore } from "../_config/api";
import "../_config/ReactotronConfig";
import { userManager } from "../_config/userManager";
import createStore from "./createStore";
import rootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";

const sagaMonitor =
  process.env.NODE_ENV === "development"
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const store: Store = createStore(rootReducer, [sagaMiddleware]);
sagaMiddleware.run(rootSaga);

loadUser(store, userManager);
injectStore(store);

export { store };
