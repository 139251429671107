import { Outlet } from "react-router-dom";
import { useRef } from "react";
import { Box, Flex } from "@chakra-ui/react";

import { Map } from "../../components/Organismos/Map";
import Spritesheet from "../../_assets/imgs/drawControl/spritesheet.png";
import Rule from "../../_assets/imgs/measureControl/rule.png";

export const MapPage = () => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Flex
      direction="column"
      className="bar-light"
      flex={1}
      h="calc(100vh - 60px)"
      w="100%"
      sx={{
        ".leaflet-bar a, .leaflet-bar, .header-control, .leaflet-bar:not(.leaflet-draw-toolbar):not(.leaflet-popup-notice) a:hover, .leaflet-control-attribution, .leaflet-control-scale, .leaflet-control-scale-line, .leaflet-measure-resultpopup .leaflet-popup-content-wrapper":
          {
            bgColor: "white",
            color: "gray.600"
          },
        ".leaflet-popup-notice": {
          bgColor: "white"
        },
        ".leaflet-bar a:hover, .leaflet-bar button:hover": {
          color: "gray.800"
        },
        ".leaflet-bar a, .leaflet-bar a:hover": {
          borderBottomColor: "white"
        },
        ".leaflet-control-scale-line": {
          borderColor: "gray.600"
        },
        ".leaflet-draw-toolbar a": {
          backgroundImage: `url(${Spritesheet}) !important`
        },
        ".leaflet-control-measure-toggle": {
          backgroundImage: `url(${Rule}) !important`
        }
      }}
    >
      <Map>
        <Outlet />
      </Map>
      <Box ref={ref}></Box>
    </Flex>
  );
};
