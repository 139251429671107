/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from "@chakra-ui/react";
import { GeoJsonObject } from "geojson";
import L from "leaflet";
import { useState } from "react";
import { GeoJSON } from "react-leaflet";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterGeometriaFotos360 } from "store/modules/mapa/panorama/actions/obterGeometriaFotos360";

export const StreetViewControl = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const dispatch = useAppDispatch();
  const { isRequesting, successPayload, requestNumber } = useTypedSelector(
    (state) => state.mapa.panorama.obterGeometriaFotos360
  );

  const onClick = () => {
    if (isRequesting) return;
    setIsActive((state) => !state);
    if (!successPayload) {
      dispatch(obterGeometriaFotos360());
    }
  };

  return (
    <>
      <div
        className={`leaflet-control leaflet-bar ${
          isActive && "button-enabled"
        }`}
      >
        <Link onClick={onClick} title="Fotos 360" fontSize="md">
          <i className="fas fa-street-view"></i>
        </Link>
      </div>
      {successPayload && isActive && (
        <GeoJSON
          key={requestNumber}
          eventHandlers={{
            click: (f) => {
              const id = f.layer.feature.id;
              navigate(`/mapa/panoramica/${id}/visualizacao`);
            }
          }}
          pointToLayer={(feature, latlng) => {
            return L.circleMarker(latlng, { radius: 2, pane: "markerPane" });
          }}
          data={successPayload as GeoJsonObject}
        />
      )}
    </>
  );
};
