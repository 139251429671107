import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useTypedSelector } from "../../store/hooks";

export const Protected = () => {
  const location = useLocation();
  const { user, isLoadingUser } = useTypedSelector((state) => state.oidc);
  const { isSignout } = useTypedSelector((state) => state.auth.account);

  if ((!user || user.expired) && !isLoadingUser && !isSignout) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!user) return null;

  if (user.profile.role && user.profile.role.includes("Colaborador")) {
    window.location.href = process.env.REACT_APP_FRONTEND_INTERNAL || "/";
    return null;
  }

  return <Outlet />;
};
