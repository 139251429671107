import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { select, SelectEffect } from "redux-saga/effects";

import { store } from "../index";
import { RootState } from "../modules/rootReducer";

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): Dispatch<AnyAction> =>
  useDispatch<AppDispatch>();

export function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}
