import { Flex } from "@chakra-ui/react";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./styles.css";

import { PopupNotification } from "./components/PopupNotification";
import PopupNotificationProvider from "./context/PopupNotificationProvider";
import { ControlPortal } from "./Controls/base/ControlPortal";
import { ClickPointControl } from "./Controls/ClickPointControl";
import { DrawControl } from "./Controls/DrawControl";
import { HomeControl } from "./Controls/HomeControl";
import { LabelControl } from "./Controls/LabelControl";
import { LayerControl } from "./Controls/LayerControl";
import { LegendControl } from "./Controls/LegendControl";
import { MeasureMapControl } from "./Controls/MeasureMapControl";
import { VisibilityControl } from "./Controls/VisibilityControl";
import { ZoomBoxControl } from "./Controls/ZoomBoxContainer";
import { memo } from "react";
import { BoundRedirector } from "./Controls/BoundRedirector";
import { LayerList } from "./Controls/LayerControl/components/LayerList";
import { StreetViewControl } from "./Controls/StreetViewControl";

type Props = {
  children?: React.ReactNode;
};

export const MapWrapper = ({ children }: Props) => {
  return (
    <Flex h="full" position="relative">
      <PopupNotificationProvider>
        <MapContainer
          style={{
            height: "100%",
            width: "100%"
          }}
          zoomControl={false}
          preferCanvas={true}
        >
          <BoundRedirector />
          <ZoomControl
            position="bottomright"
            zoomInTitle="Aumentar zoom"
            zoomOutTitle="Diminuir zoom"
          />
          <ZoomBoxControl position="bottomright" />
          <ScaleControl position="bottomleft" imperial={false} />
          <DrawControl position="topright" />
          <MeasureMapControl position="topright" />

          <LegendControl />

          <ControlPortal position="topright">
            <ClickPointControl />
            <LabelControl />
          </ControlPortal>

          <ControlPortal position="bottomleft">
            <LayerControl>
              <LayerList />
            </LayerControl>
          </ControlPortal>

          <ControlPortal position="bottomright">
            <StreetViewControl />
            <VisibilityControl />
            <HomeControl />
          </ControlPortal>
          {children}
        </MapContainer>
        <PopupNotification />
      </PopupNotificationProvider>
    </Flex>
  );
};

export const Map = memo(MapWrapper);
