/* eslint-disable @typescript-eslint/no-explicit-any */
import L from "leaflet";
import { ControlPosition } from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { drawLocales } from "./index.locales";
import "./style.css";

type Props = {
  position: ControlPosition;
};

drawLocales();

const leaflet: any = L;
delete leaflet.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export const DrawControl = ({ position }: Props) => {
  const _onEdited = (e: any) => {
    console.log(`_onEdited: edited layers`, e);
  };

  const _onCreated = (e: any) => {
    const type = e.layerType;
    const layer = e.layer;
    layer.options.opacity = 1;
    if (type === "marker") {
      // Do marker specific actions
      console.log("_onCreated: marker created", e);
    } else {
      console.log("_onCreated: something else created:", type, e);
    }
    // Do whatever else you need to. (save to db; etc)
  };

  const _onDeleted = (e: any) => {
    console.log(`onDeleted: removed  layers`, e);
  };

  const _onMounted = (drawControl: any) => {
    console.log("_onMounted", drawControl);
  };

  const _onEditStart = (e: any) => {
    console.log("_onEditStart", e);
  };

  const _onEditStop = (e: any) => {
    console.log("_onEditStop", e);
  };

  const _onDeleteStart = (e: any) => {
    console.log("_onDeleteStart", e);
  };

  const _onDeleteStop = (e: any) => {
    console.log("_onDeleteStop", e);
  };

  return (
    <FeatureGroup pane="overlayPane">
      <EditControl
        position={position}
        onEdited={_onEdited}
        onCreated={_onCreated}
        onDeleted={_onDeleted}
        onMounted={_onMounted}
        onEditStart={_onEditStart}
        onEditStop={_onEditStop}
        onDeleteStart={_onDeleteStart}
        onDeleteStop={_onDeleteStop}
        draw={{
          polyline: true,
          rectangle: true,
          circlemarker: false,
          circle: true,
          polygon: true
        }}
      />
    </FeatureGroup>
  );
};
