import { Button, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "../../../store/hooks";
import { obterConfiguracao } from "../../../store/modules/configuracao/actions/obterConfiguracao";
import { Logo } from "../../components/Logo";
import { UserDescription } from "../../components/UserDescription";

export const Header = () => {
  const dispatch = useAppDispatch();
  const { user } = useTypedSelector((state) => state.oidc);
  const { successPayload } = useTypedSelector(
    (state) => state.configuracao.obterConfiguracao
  );

  useEffect(() => {
    dispatch(obterConfiguracao());
  }, []);

  return (
    <Flex
      as="nav"
      px="8"
      position="sticky"
      zIndex="1"
      top="0"
      w="full"
      bgColor="white"
      borderBottom="1px solid rgb(0 0 0 / 7%)"
      minH="60px"
    >
      <Flex maxW="1200px" w="full" margin="0 auto">
        <Flex w="100%" alignItems="center">
          <Logo />
          <Flex
            as={Link}
            to="/"
            ml="1"
            h="full"
            px="4"
            align="center"
            _hover={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
          >
            Início
          </Flex>
        </Flex>
        <Flex w={{ lg: "100%" }} alignItems="center" justifyContent="flex-end">
          {user &&
            user.profile.role &&
            user.profile.role.includes("Colaborador") && (
              <Button
                as={Link}
                to="/inside"
                variant="outline"
                colorScheme={successPayload?.corTema.nome}
                size="sm"
              >
                Área do Colaborador
              </Button>
            )}
          <UserDescription />
        </Flex>
      </Flex>
    </Flex>
  );
};
