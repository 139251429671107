import { Box, Collapse, Divider, Flex, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { RiMapLine } from "react-icons/ri";
import { useTypedSelector } from "../../../../../store/hooks";
import { LayerConfig } from "./components/LayerConfig";

export const VisibilityControl = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { camadas } = useTypedSelector(
    (state) => state.mapa.camada.selecionarCamada
  );
  const qtdCamadas = camadas.length;

  if (qtdCamadas == 0) return null;

  return (
    <Box
      className="leaflet-control leaflet-bar"
      position="absolute"
      bottom="15px"
      right="45px"
      minW={isOpen ? "300px" : "205px"}
      py={3}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Flex justifyContent="space-between" alignItems="center" px={6}>
        <Text mr="2" fontSize="sm" fontWeight="medium">
          {isOpen ? "Camadas ativas" : "Ver camadas ativas"}
        </Text>
        <Flex alignItems="center">
          <Text fontSize="sm" mr="1">
            {qtdCamadas}
          </Text>
          <Text fontSize="sm" color="main.500">
            <RiMapLine />
          </Text>
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack
          mt={isOpen ? 2 : 0}
          pt={1}
          px={6}
          pb={2}
          maxHeight="calc(100vh - 200px)"
          overflowY="auto"
          divider={<Divider />}
        >
          {camadas.map((c) => (
            <LayerConfig key={c.id} camada={c} />
          ))}
        </VStack>
      </Collapse>
    </Box>
  );
};
