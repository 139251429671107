/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CorTema {
  nome: string;
  paleta: { [x: number]: string };
}

export interface Usuario {
  id: string;
  nome: string;
  email: string;
  perfis: string[];
  funcionalidades: string[];
  paleta: CorTema | null;
}

interface State {
  isRequesting: boolean;
  usuario: Usuario | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  usuario: null,
  errors: []
};

const obterUsuarioSlice = createSlice({
  name: "auth@obterUsuario",
  initialState,
  reducers: {
    obterUsuario: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterUsuarioSuccess: (state, action: PayloadAction<Usuario>): void => {
      state.isRequesting = false;
      state.usuario = action.payload;
    },
    alterarPaletaUsuarioSuccess: (
      state,
      action: PayloadAction<CorTema>
    ): void => {
      state.isRequesting = false;
      state.usuario!.paleta = action.payload;
    },
    obterUsuarioFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

export const {
  obterUsuario,
  obterUsuarioSuccess,
  alterarPaletaUsuarioSuccess,
  obterUsuarioFailed
} = obterUsuarioSlice.actions;
export default obterUsuarioSlice.reducer;
