/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { Point } from "geojson";

import api from "../../../../../_config/api";
import { formatError } from "../../../../../utils/errorHelper";

interface ObterDetalheFoto360Request {
  idFoto: string;
}

interface PontosVizinhos {
  id: string;
  azimute: number;
  azimuteCorrigido: number;
  sequencial: number;
  frente: boolean;
}

interface ObterDetalheFoto360Response {
  id: string;
  geom: Point;
  grausPrimeiroVizinho: number;
  vizinhos: PontosVizinhos[];
}

interface State {
  isRequesting: boolean;
  successPayload: ObterDetalheFoto360Response | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: null,
  errors: []
};

const obterDetalheFoto360Slice = createSlice({
  name: "mapa.panorama@obterDetalheFoto360",
  initialState,
  reducers: {
    obterDetalheFoto360: (
      state,
      action: PayloadAction<ObterDetalheFoto360Request>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterDetalheFoto360Success: (
      state,
      action: PayloadAction<ObterDetalheFoto360Response>
    ): void => {
      const payload = {
        ...action.payload,
        grausPrimeiroVizinho: 0
      };
      const vizinhos = action.payload.vizinhos;
      if (vizinhos.length > 0) {
        const primeiroVizinho = vizinhos[0];
        payload.grausPrimeiroVizinho = primeiroVizinho.frente
          ? primeiroVizinho.azimute
          : 0;
      }

      state.isRequesting = false;
      state.successPayload = payload;
    },
    obterDetalheFoto360Failed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    },
    reset: (state): void => {
      state.isRequesting = false;
      state.successPayload = null;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: ObterDetalheFoto360Request;
}) {
  try {
    const { idFoto } = params.payload;

    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/panorama/${idFoto}/detalhes`
    );
    const { data } = response;

    yield put(obterDetalheFoto360Success(data));
  } catch (error) {
    yield put(obterDetalheFoto360Failed(formatError(error)));
  }
}

export const {
  obterDetalheFoto360,
  obterDetalheFoto360Success,
  obterDetalheFoto360Failed,
  reset
} = obterDetalheFoto360Slice.actions;
export const { reducer } = obterDetalheFoto360Slice;
export const saga = takeLatest(obterDetalheFoto360.type, sagaFunction);
