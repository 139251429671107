import { Box } from "@chakra-ui/react";
import { usePopupNotification } from "../context/PopupNotificationProvider";

export const PopupNotification = () => {
  const { isOpen, childrenElement } = usePopupNotification();

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      className="leaflet-control leaflet-popup-notice"
      px={4}
      py={2}
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      bottom={20}
      zIndex={1000}
      borderRadius="md"
    >
      {childrenElement}
    </Box>
  );
};
