/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { useState } from "react";
import { useMap } from "react-leaflet";
import { Camada } from "../../../../../../store/modules/mapa/camada/actions/selecionarCamada";
import { updateLayerMap } from "../../../../../../utils/map/layers";
import { MostrarLegenda } from "./MostrarLegenda";
import { RemoverLayer } from "./RemoverLayer";

type Props = {
  camada: Camada;
};

export const LayerConfig = ({ camada }: Props) => {
  const map = useMap();
  const colorSlide = useColorModeValue("main.400", "main.500");
  const [sliderValue, setSliderValue] = useState(100);
  const [showTooltip, setShowTooltip] = useState(false);

  const onChange = (value: number) => {
    setSliderValue(value);
  };

  const onSave = () => {
    updateLayerMap(
      camada.id,
      map,
      camada.tipoServico,
      sliderValue / 100,
      camada.zIndex
    );
  };

  return (
    <Box w="full">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="sm">{camada.nome}</Text>
        <HStack spacing={0}>
          <MostrarLegenda
            id={camada.id}
            idTipoServicoCamada={camada.tipoServico}
          />
          <RemoverLayer id={camada.id} />
        </HStack>
      </Flex>
      <Flex>
        <Slider
          id="slider"
          defaultValue={100}
          min={0}
          max={100}
          onChange={(v) => onChange(v)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack>
            <SliderFilledTrack bgColor={colorSlide} />
          </SliderTrack>
          <Tooltip
            hasArrow
            placement="top"
            isOpen={showTooltip}
            label={`${sliderValue}%`}
          >
            <SliderThumb bgColor={colorSlide} h={2.5} w={2.5} />
          </Tooltip>
        </Slider>
        <Box pl="4">
          <Button size="xs" onClick={onSave}>
            Aplicar
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};
