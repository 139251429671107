import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  VStack
} from "@chakra-ui/react";

type Props = {
  children?: React.ReactNode;
};

export const LayerControl = ({ children }: Props) => {
  const colorBase = useColorModeValue("gray.800", "white");

  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Box className="leaflet-control leaflet-bar">
      <Popover
        placement="top-start"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnBlur={false}
        closeDelay={200}
      >
        <PopoverTrigger>
          <Box
            bgColor="white"
            _dark={{ bgColor: "gray.900" }}
            borderRadius="md"
          >
            <Button colorScheme="gray" size="sm" variant="ghost">
              Ver camadas
            </Button>
          </Box>
        </PopoverTrigger>
        <PopoverContent pb={2} pt={1} userSelect="none">
          <PopoverCloseButton />
          <PopoverBody color={colorBase} px={0}>
            <VStack alignItems="flex-start">
              {children && <Box w="full">{children}</Box>}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
