import { Reducer } from "redux";
import { SESSION_TERMINATED } from "redux-oidc";

interface AccountState {
  isSignout: boolean;
}

const initialState: AccountState = { isSignout: false };

export const account: Reducer<AccountState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SESSION_TERMINATED:
      return { ...state, isSignout: true };
    default:
      return state;
  }
};
