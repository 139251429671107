/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon, IconProps } from "@chakra-ui/react";
import { IoImageOutline } from "react-icons/io5";
import { RiEarthLine, RiMapLine } from "react-icons/ri";
import { TipoServicoCamada } from "../../../store/enums/tipoServicoCamada";

type Props = {
  camadaType: TipoServicoCamada;
} & IconProps;

export const MapTypeIcon = ({ camadaType, ...rest }: Props) => {
  const iconRender = (): any => {
    switch (camadaType) {
      case TipoServicoCamada.WMS:
        return RiMapLine;
      case TipoServicoCamada.IMAGEM:
        return IoImageOutline;
      case TipoServicoCamada.ESRI_REST:
        return RiEarthLine;
    }
  };

  return <Icon as={iconRender()} {...rest} />;
};
