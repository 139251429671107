import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "../../../../utils/errorHelper";
import api from "../../../../_config/api";
import {
  obterCamadas,
  obterCamadasFailed,
  ObterCamadasResponse,
  obterCamadasSuccess
} from "./actions/obterCamadas";

function* obterTodosComCamadas() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/mapa/grupo/camada`
    );

    const { data }: { data: ObterCamadasResponse[] } = response;

    yield put(obterCamadasSuccess(data ? data : []));
  } catch (error) {
    yield put(obterCamadasFailed(formatError(error)));
  }
}

export default all([takeLatest(obterCamadas.type, obterTodosComCamadas)]);
