import {
  BrowserRouter as Router,
  Route,
  Routes as RoutesList
} from "react-router-dom";
import { LayoutDefault } from "../../layouts/LayoutDefault";
import { LayoutBody } from "../../layouts/LayoutDefault/Body";

import { Protected } from "./Protected";
import { AcessoNegado } from "../../pages/Auth/AcessoNegado";
import { CallbackPage } from "../../pages/Auth/CallbackPage";
import { CallbackSignout } from "../../pages/Auth/CallbackSignout";
import { Login } from "../../pages/Auth/Login";

import { Home } from "../../pages/Home";
import { Inside } from "../../pages/Inside";
import { MapaRoutes } from "./modules/MapaRoutes";

export const Routes = () => {
  return (
    <Router>
      <RoutesList>
        <Route path="/" element={<LayoutDefault />}>
          <Route element={<LayoutBody />}>
            <Route index element={<Home />} />
            <Route path="/mapa/*" element={<MapaRoutes />} />
          </Route>
        </Route>
        <Route element={<Protected />}>
          <Route path="/inside" element={<Inside />} />
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="callback" element={<CallbackPage />} />
        <Route path="signout-callback-oidc" element={<CallbackSignout />} />
        <Route path="acessonegado" element={<AcessoNegado />} />
      </RoutesList>
    </Router>
  );
};
