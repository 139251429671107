import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "../../../utils/errorHelper";
import api from "../../../_config/api";

import {
  obterConfiguracao,
  obterConfiguracaoFailed,
  ObterConfiguracaoResponse,
  obterConfiguracaoSuccess
} from "./actions/obterConfiguracao";

function* obter() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/configuracoes`
    );

    const { data }: { data: ObterConfiguracaoResponse } = response;

    yield put(obterConfiguracaoSuccess(data));
  } catch (error) {
    yield put(obterConfiguracaoFailed(formatError(error)));
  }
}

export default all([takeLatest(obterConfiguracao.type, obter)]);
