import { Image, useColorModeValue } from "@chakra-ui/react";

export const Logo = () => {
  const logo = useColorModeValue(
    `${process.env.REACT_APP_API}api/v1/Customizacao/logo/light`,
    `${process.env.REACT_APP_API}api/v1/Customizacao/logo/dark`
  );

  return (
    <Image src={logo} alt="logo sit" maxW={{ base: "75px", lg: "75px" }} />
  );
};
