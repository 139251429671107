import { Alert, Box, Skeleton, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  useAppDispatch,
  useTypedSelector
} from "../../../../../../../store/hooks";
import { obterCamadas } from "../../../../../../../store/modules/mapa/camada/actions/obterCamadas";
import { GroupLayer } from "./GroupLayer";

export const LayerList = () => {
  const dispatch = useAppDispatch();
  const { successPayload, isRequesting } = useTypedSelector(
    (state) => state.mapa.camada.obterCamadas
  );

  useEffect(() => {
    dispatch(obterCamadas());
  }, []);

  return (
    <Box w="full">
      <Text fontWeight="medium" mb={3} px={4}>
        Camadas
      </Text>
      <Box w="full" h="100vh" maxH="550px" overflow="auto">
        <Box px={4}>
          {isRequesting ? (
            <Stack>
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
            </Stack>
          ) : successPayload.length == 0 ? (
            <Alert py={2}>Não possui nenhuma camada.</Alert>
          ) : (
            successPayload.map((g) => (
              <GroupLayer
                key={g.id}
                idGrupo={g.id}
                nome={g.nome}
                ordem={g.ordem}
                camadas={g.camadas}
              />
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
};
