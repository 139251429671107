/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, Tooltip } from "@chakra-ui/react";
import { RiFileListLine } from "react-icons/ri";
import { TipoServicoCamada } from "../../../../../../store/enums/tipoServicoCamada";
import {
  useAppDispatch,
  useTypedSelector
} from "../../../../../../store/hooks";
import { mudarVisualizacaoLegenda } from "../../../../../../store/modules/mapa/camada/actions/selecionarCamada";

type Props = {
  id: string;
  idTipoServicoCamada: TipoServicoCamada;
};

export const MostrarLegenda = ({ id, idTipoServicoCamada }: Props) => {
  const dispatch = useAppDispatch();
  const { camadas } = useTypedSelector(
    (state) => state.mapa.camada.selecionarCamada
  );

  if (
    idTipoServicoCamada != TipoServicoCamada.ESRI_REST &&
    idTipoServicoCamada != TipoServicoCamada.WMS
  ) {
    return null;
  }

  const isActive = camadas.some((x) => x.id == id && x.mostrarLegenda);

  const onClick = () => {
    dispatch(mudarVisualizacaoLegenda({ id }));
  };

  return (
    <Tooltip label="Ver legenda" placement="top">
      <IconButton
        aria-label={"mostrar legenda"}
        size="sm"
        variant={isActive ? "mainOutline" : "ghost"}
        color={isActive ? "" : "gray.500"}
        onClick={onClick}
        _focus={{ boxShadow: "none" }}
        icon={<RiFileListLine />}
      />
    </Tooltip>
  );
};
