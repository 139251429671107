import { BBox } from "geojson";
import { LatLngBoundsLiteral } from "leaflet";

export const ToLatLngBound = (bbox: BBox): LatLngBoundsLiteral => {
  const latLngBound: LatLngBoundsLiteral = [
    [bbox[1], bbox[0]],
    [bbox[3], bbox[2]]
  ];
  return latLngBound;
};
