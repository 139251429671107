import { Box, VStack, Text } from "@chakra-ui/react";
import { TipoServicoCamada } from "../../../../../store/enums/tipoServicoCamada";
import { useTypedSelector } from "../../../../../store/hooks";
import { EsriLegend } from "./EsriLegend";
import { WmsLegend } from "./WmsLegend";

export const LegendControl = () => {
  const { camadas } = useTypedSelector(
    (state) => state.mapa.camada.selecionarCamada
  );

  const camadasComLegendaAtiva = camadas.filter(
    (c) =>
      c.mostrarLegenda &&
      (c.tipoServico == TipoServicoCamada.WMS ||
        c.tipoServico == TipoServicoCamada.ESRI_REST)
  );

  if (camadasComLegendaAtiva.length == 0) return null;

  return (
    <Box
      className="leaflet-control leaflet-bar leaflet-legend-custom"
      position="absolute"
      bottom="32px"
      left="130px"
      minW="300px"
    >
      <Box bgColor="white" px={6} py={3} borderRadius="md">
        <Text fontSize="sm" color="gray.800" mb={2} fontWeight="medium">
          Legenda
        </Text>
        <VStack alignItems="baseline">
          {camadasComLegendaAtiva.map((c) => {
            switch (c.tipoServico) {
              case TipoServicoCamada.WMS:
                return <WmsLegend key={c.id} url={c.url} />;
              case TipoServicoCamada.ESRI_REST:
                return <EsriLegend key={c.id} url={c.url} />;
              default:
                return null;
            }
          })}
        </VStack>
      </Box>
    </Box>
  );
};
