import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  useColorModeValue
} from "@chakra-ui/react";

import { TextGray } from "../../../components/Atomos/Texts/TextGray";
import { userManager } from "../../../_config/userManager";

export const AcessoNegado: React.FC = () => {
  const signOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    async function logout() {
      await userManager.signoutRedirect();
    }

    logout();
  };

  return (
    <Box
      flex="1"
      h="full"
      bgGradient={useColorModeValue(
        "linear(to-br, white 0%, gray.200 25%, white 75%)",
        "linear(to-br, gray.900 0%, gray.800 25%, gray.900 75%)"
      )}
    >
      <Flex
        maxH="400px"
        w="full"
        textAlign="center"
        direction="column"
        alignItems="center"
        py={10}
        px={8}
      >
        <Heading as="h2" textAlign="center" size="xl" mt={10} mb={4}>
          Você não possui permissão para acessar o sistema.
        </Heading>
        <TextGray>
          Contate o administrador do sistema e verifique seu cadastro.
        </TextGray>
        <Button colorScheme="blue" mt={20} onClick={signOut}>
          Voltar para o Login
        </Button>
      </Flex>
    </Box>
  );
};
