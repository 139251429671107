/* eslint-disable @typescript-eslint/no-explicit-any */
import L from "leaflet";
import { LatLngExpression } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import MarkerIcon from "../../../../../_assets/imgs/markerTooltip.svg";
import { RiCloseLine } from "react-icons/ri";
import { Box, Flex } from "@chakra-ui/react";

const LabelControlIcon = new L.Icon({
  iconUrl: MarkerIcon,
  iconSize: [20, 20]
});

type Props = {
  latLng: LatLngExpression;
  onClose: () => void;
};
export const MarkerPopup = ({ latLng, onClose }: Props) => {
  return (
    <Marker
      ref={(ref) => {
        ref?.openPopup();
      }}
      interactive={false}
      icon={LabelControlIcon}
      position={latLng}
    >
      <Popup
        autoClose={false}
        closeOnClick={false}
        closeButton={false}
        closeOnEscapeKey={false}
      >
        <Flex justifyContent="space-between">
          <Box
            contentEditable="true"
            suppressContentEditableWarning={true}
            minHeight="10px"
            maxW={200}
            width="100%"
            fontSize="lg"
          >
            Digite seu texto
          </Box>
          <Flex alignItems="flex-start">
            <Box as="button" onClick={onClose}>
              <RiCloseLine />
            </Box>
          </Flex>
        </Flex>
      </Popup>
    </Marker>
  );
};
