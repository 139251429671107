import { useDisclosure } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";

interface Context {
  isOpen: boolean;
  open: (element: React.ReactNode) => void;
  close: () => void;
  toggle: (element: React.ReactNode) => void;
  childrenElement: React.ReactNode;
}

const PopupNotificationContext = createContext<Context>({
  isOpen: false,
  childrenElement: null,
  toggle: () => null,
  open: () => null,
  close: () => null
});

const Provider: React.FC = ({ children }) => {
  const [childrenElement, setChildrenElement] = useState<React.ReactNode>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const open = (element: React.ReactNode) => {
    setChildrenElement(element);
    onOpen();
  };

  const close = () => {
    onClose();
  };

  const toggle = (element: React.ReactNode) => {
    isOpen ? close() : open(element);
  };

  return (
    <PopupNotificationContext.Provider
      value={{
        childrenElement,
        isOpen,
        open,
        close,
        toggle
      }}
    >
      {children}
    </PopupNotificationContext.Provider>
  );
};

export const usePopupNotification = (): Context =>
  useContext(PopupNotificationContext);

export default Provider;
