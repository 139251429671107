import { Flex, useColorModeValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Header } from "./Header";

export const LayoutDefault = () => {
  const barClass = useColorModeValue("bar-light", "bar-dark");

  return (
    <Flex direction="column" flex={1} h="full" className={barClass}>
      <Header />
      <Outlet />
    </Flex>
  );
};
