/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, Tooltip } from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";
import { useMap } from "react-leaflet";
import { removeLayerMap } from "../../../../../../utils/map/layers";

type Props = {
  id: string;
};

export const RemoverLayer = ({ id }: Props) => {
  const map = useMap();

  const onRemove = () => {
    removeLayerMap(id, map);
  };

  return (
    <Tooltip label="Remover da lista" placement="top">
      <IconButton
        aria-label={"remover layer"}
        size="sm"
        variant="ghost"
        color="gray.500"
        onClick={onRemove}
        icon={<RiCloseLine />}
        _focus={{ boxShadow: "none" }}
      />
    </Tooltip>
  );
};
