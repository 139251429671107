import { useEffect, useRef } from "react";
import { Marker, useMap } from "react-leaflet";
import { LatLngLiteral, Marker as LeafletMarker } from "leaflet";
import "leaflet-rotatedmarker";
import L from "leaflet";

import MarkerImage from "_assets/imgs/mapa/markers/street-view-marker.svg";

const markerIcon = new L.Icon({
  iconUrl: MarkerImage,
  iconSize: [40, 40],
  iconAnchor: [20, 24]
});

type Props = {
  children?: React.ReactNode;
  rotationAngle: number;
  position: LatLngLiteral;
};

export const RotatedMarker = ({ children, position, rotationAngle }: Props) => {
  const markerRef = useRef<LeafletMarker>(null);
  const map = useMap();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const marker = markerRef.current as any;
    if (marker) {
      marker.setRotationAngle(rotationAngle);
    }
  }, [rotationAngle]);

  useEffect(() => {
    map.setView(position);
  }, [map, position]);

  return (
    <Marker ref={markerRef} icon={markerIcon} position={position}>
      {children}
    </Marker>
  );
};
