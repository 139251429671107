import { __DEV__ } from "@chakra-ui/utils";
import { ColorMode, StorageManager } from "@chakra-ui/react";

const hasSupport = () => typeof Storage !== "undefined";
type MaybeColorMode = ColorMode | undefined;

export const storageKey = "sit-public-style";

export const localStorageManager: StorageManager = {
  get(init?) {
    if (!hasSupport()) return init;
    try {
      const value = localStorage.getItem(storageKey) as MaybeColorMode;
      return value ?? init;
    } catch (error) {
      if (__DEV__) {
        console.log(error);
      }
      return init;
    }
  },
  set(value) {
    if (!hasSupport()) return;
    try {
      localStorage.setItem(storageKey, value);
    } catch (error) {
      if (__DEV__) {
        console.log(error);
      }
    }
  },
  type: "localStorage"
};
