import { Route, Routes } from "react-router-dom";

import { MapPage } from "pages/Map";
import { StreetView } from "pages/Map/StreetView";

export const MapaRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MapPage />}>
        <Route path="panoramica/:id/visualizacao" element={<StreetView />} />
      </Route>
    </Routes>
  );
};
