/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text } from "@chakra-ui/react";
import L from "leaflet";
import { useEffect, useState } from "react";
import { useMapEvents } from "react-leaflet";
import { usePopupNotification } from "../../context/PopupNotificationProvider";
import { MarkerPopup } from "./MarkerPopup";

export const LabelControl = () => {
  const [isActive, setIsActive] = useState(false);
  const [markers, setMarkers] = useState<L.LatLngExpression[]>([]);
  const { open, close } = usePopupNotification();

  useMapEvents({
    click: (e) => {
      if (isActive) {
        setMarkers((state) => [...state, e.latlng]);
        setIsActive(false);
        close();
      }
    }
  });

  const remove = (position: number) => {
    setMarkers((state) =>
      state.filter((x, i) => {
        return i != position;
      })
    );
  };

  const onClick = () => {
    setIsActive((state) => !state);
    close();
  };

  useEffect(() => {
    if (isActive) {
      open(
        <Text>
          <strong>Clique</strong> sobre o mapa para adicionar uma label.
        </Text>
      );
    }
  }, [isActive]);

  return (
    <>
      <div
        className={`leaflet-control leaflet-bar ${
          isActive && "button-enabled"
        }`}
      >
        <a onClick={onClick} title="Inserir rótulo">
          <i className="fas fa-tag"></i>
        </a>
      </div>
      {markers.map((x, i) => (
        <MarkerPopup key={x.toString()} latLng={x} onClose={() => remove(i)} />
      ))}
    </>
  );
};
