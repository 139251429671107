import { Box, Flex } from "@chakra-ui/react";

import { Detalhe } from "./Detalhe";

export const Home = () => {
  return (
    <Flex h="full" pt="20" direction="column" justifyContent="space-between">
      <Detalhe />

      <Box w="full" bgColor="gray.900" color="white" textAlign="center" py="12">
        Sistema de Informações Territoriais - EGL Engenharia
      </Box>
    </Flex>
  );
};
