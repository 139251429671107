import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { useTypedSelector } from "../../../../../store/hooks";

export const BoundRedirector = () => {
  const map = useMap();
  const { successPayload } = useTypedSelector(
    (state) => state.configuracao.obterConfiguracao
  );

  useEffect(() => {
    if (successPayload) {
      map.fitBounds(successPayload.bboxLatLng);
    }
  }, [map, successPayload]);

  return null;
};
