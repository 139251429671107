import { useMap } from "react-leaflet";
import { useTypedSelector } from "../../../../store/hooks";

export const HomeControl = () => {
  const map = useMap();

  const { successPayload } = useTypedSelector(
    (state) => state.configuracao.obterConfiguracao
  );

  const goHome = () => {
    if (successPayload) {
      map.fitBounds(successPayload.bboxLatLng);
    }
  };

  return (
    <div className="leaflet-control leaflet-bar">
      <a onClick={goHome} title="Posição inicial">
        <i className="fa fa-home"></i>
      </a>
    </div>
  );
};
