/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createToast } from "./createToast";

export function formatError(error: any): string[] {
  const errorNotDefined = "Não foi possivel realizar a operação";
  const toast = createToast();

  if (error.response) {
    if (error.response.status === 400) {
      if (error.response.data.errors) {
        toast({
          title: errorNotDefined,
          status: "error",
          position: "bottom"
        });
        return [errorNotDefined];
      }

      const errorList = [];
      for (const key of Object.keys(error.response.data)) {
        if (typeof error.response.data[key] === typeof []) {
          for (const errorMessage of error.response.data[key]) {
            errorList.push(errorMessage);
            toast({
              title: errorMessage,
              status: "info",
              position: "bottom"
            });
          }
        } else {
          toast({
            title: errorNotDefined,
            status: "error",
            position: "bottom"
          });
          return [errorNotDefined];
        }
      }
      return errorList;
    }

    if (error.response.status === 403) {
      toast({
        title: "Operação não autorizada.",
        status: "info",
        position: "bottom"
      });
      return [error.response.statusText];
    }

    if (error.response.status === 401) {
      return [error.response.statusText];
    }

    if (error.response.status === 404) {
      return [error.response.statusText];
    }
  }
  toast({
    title: errorNotDefined,
    status: "error",
    position: "bottom"
  });
  return [error.response.statusText];
}
