import { ChakraProvider, extendTheme, LightMode } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { store } from "./store";
import { useTypedSelector } from "./store/hooks";
import { Routes } from "./_config/routes";
import { userManager } from "./_config/userManager";
import "./_styles/all.css";
import { localStorageManager } from "./_styles/storageManager";
import { theme } from "./_styles/theme";

const ThemeApp = () => {
  const config = useTypedSelector(
    (state) => state.configuracao.obterConfiguracao.successPayload
  );

  const customTheme = extendTheme({
    ...theme,
    colors: {
      main: config?.corTema.paleta
    }
  });

  return (
    <ChakraProvider
      resetCSS
      theme={customTheme}
      colorModeManager={localStorageManager}
    >
      <LightMode>
        <Routes />
      </LightMode>
    </ChakraProvider>
  );
};

export const App = () => {
  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ThemeApp />
      </OidcProvider>
    </Provider>
  );
};
