/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TipoServicoCamada } from "../../../../enums/tipoServicoCamada";

interface Camada {
  idCamada: string;
  nome: string;
  url: string;
  ordem: number;
  publico: boolean;
  ativo: boolean;
  tipoServico: {
    value: TipoServicoCamada;
    name: string;
  };
}

export interface ObterCamadasResponse {
  id: string;
  nome: string;
  ordem: number;
  camadas: Camada[];
}

interface State {
  isRequesting: boolean;
  successPayload: ObterCamadasResponse[];
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: [],
  errors: []
};

const obterCamadasSlice = createSlice({
  name: "mapa.camada@obterCamadas",
  initialState,
  reducers: {
    obterCamadas: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterCamadasSuccess: (
      state,
      action: PayloadAction<ObterCamadasResponse[]>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterCamadasFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

export const { obterCamadas, obterCamadasSuccess, obterCamadasFailed } =
  obterCamadasSlice.actions;
export default obterCamadasSlice.reducer;
