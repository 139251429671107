import { Box, Flex, AspectRatio, Portal } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterDetalheFoto360 } from "store/modules/mapa/panorama/actions/obterDetalheFoto360";
import { ArrowDirections } from "./components/ArrowDirections";
import { ImageViewer } from "./components/ImageViewer";
import { MiniMap } from "./components/MiniMap";
import { RotatedMarker } from "./components/RotatedMarker";

export const StreetView = () => {
  const [rotationX, setRotationX] = useState<number>(0);
  const [inclinationY, setInclinationY] = useState<number>(0);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { successPayload } = useTypedSelector(
    (state) => state.mapa.panorama.obterDetalheFoto360
  );

  const grausPrimeiroPonto = successPayload?.grausPrimeiroVizinho ?? 0;
  const rotationAngle = grausPrimeiroPonto + rotationX;
  const [lng, lat] = successPayload?.geom.coordinates || [0, 0];
  const position = { lat, lng };

  useEffect(() => {
    if (id) {
      dispatch(obterDetalheFoto360({ idFoto: id }));
    }
  }, [id]);

  const navigateProximaFoto = (idProximaFoto: string) => {
    navigate(
      `/mapa/panoramica/${idProximaFoto}/visualizacao?initial_yaw=${rotationX}&initial_pitch=${inclinationY}`
    );
  };

  const navigateToMap = () => {
    navigate(`/mapa`);
  };

  const handleToggleFullScreen = () => {
    setIsFullScreen((state) => !state);
  };

  if (!isFullScreen) {
    return (
      <Portal>
        <AspectRatio
          position="absolute"
          right={{ base: "0", md: "14" }}
          bottom="3"
          w="min(500px, 100vw)"
          ratio={16 / 9}
          zIndex={9999}
        >
          <Box
            borderRadius="md"
            boxShadow="md"
            sx={{
              ".pnlm-container": {
                borderRadius: "md"
              }
            }}
          >
            <RotatedMarker rotationAngle={rotationAngle} position={position} />
            <ArrowDirections
              vizinhos={
                !successPayload
                  ? []
                  : successPayload.vizinhos.map((x) => ({
                      id: x.id,
                      angulo: x.azimuteCorrigido
                    }))
              }
              isFullScreen={isFullScreen}
              rotation={rotationX}
              onClick={(id) => navigateProximaFoto(id)}
            />
            <ImageViewer
              id={id}
              onChangeYaw={(yaw) => setRotationX(yaw)}
              onChangePitch={(pitch) => setInclinationY(pitch)}
              isFullScreen={isFullScreen}
              toggleFullScreen={handleToggleFullScreen}
              onClose={navigateToMap}
            />
          </Box>
        </AspectRatio>
      </Portal>
    );
  }

  return (
    <Portal>
      <Flex
        position="fixed"
        zIndex={1300}
        top="0"
        h="100vh"
        w="100vw"
        sx={{
          ".hidden": {
            display: "none"
          }
        }}
      >
        <MiniMap
          position={position}
          rotationAngle={grausPrimeiroPonto + rotationX}
        />
        <ArrowDirections
          vizinhos={
            !successPayload
              ? []
              : successPayload.vizinhos.map((x) => ({
                  id: x.id,
                  angulo: x.azimuteCorrigido
                }))
          }
          isFullScreen={isFullScreen}
          rotation={rotationX}
          onClick={(id) => navigateProximaFoto(id)}
        />
        <ImageViewer
          id={id}
          onChangeYaw={(yaw) => setRotationX(yaw)}
          onChangePitch={(pitch) => setInclinationY(pitch)}
          isFullScreen={isFullScreen}
          toggleFullScreen={handleToggleFullScreen}
          onClose={navigateToMap}
        />
      </Flex>
    </Portal>
  );
};
