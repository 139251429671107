/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Flex, useColorModeValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { TipoServicoCamada } from "../../../../../../../store/enums/tipoServicoCamada";
import {
  useAppDispatch,
  useTypedSelector
} from "../../../../../../../store/hooks";
import {
  limparCamadas,
  selecionarCamada
} from "../../../../../../../store/modules/mapa/camada/actions/selecionarCamada";
import {
  addLayerToMap,
  removeLayerMap
} from "../../../../../../../utils/map/layers";
import { MapTypeIcon } from "../../../../../../Atomos/Icons/MapTypeIcon";

type Camada = {
  idCamada: string;
  nome: string;
  url: string;
  ativo: boolean;
  publico: boolean;
  ordem: number;
  tipoServico: {
    value: TipoServicoCamada;
    name: string;
  };
};

type Props = {
  idGrupo: string;
  ordemGrupo: number;
  camada: Camada;
};

export const Layer = ({ idGrupo, ordemGrupo, camada }: Props) => {
  const dispatch = useAppDispatch();
  const map = useMap();
  const hoverBgColor = useColorModeValue("rgba(0,0,0,0.05)", "rgba(0,0,0,0.4)");
  const layersSelecionadas = useTypedSelector(
    (state) => state.mapa.camada.selecionarCamada
  );

  const thisLayer = layersSelecionadas.camadas.find(
    (x) => x.id == camada.idCamada
  );

  const selecionar = () => {
    const zIndex = 2147483647 - (ordemGrupo * 100 + camada.ordem);
    dispatch(
      selecionarCamada({
        id: camada.idCamada,
        nome: camada.nome,
        idGrupo: idGrupo,
        tipoServico: camada.tipoServico.value,
        url: camada.url,
        opacity: 1,
        zIndex: zIndex
      })
    );
    addLayerToMap({
      id: camada.idCamada,
      map: map,
      url: camada.url,
      pane: "tilePane",
      zIndex: zIndex,
      tipoServico: camada.tipoServico.value,
      opacity: 1
    });
  };

  const remover = () => {
    removeLayerMap(camada.idCamada, map);
  };

  const onChange = () => {
    if (thisLayer) {
      remover();
      return;
    }

    selecionar();
  };

  useEffect(() => {
    if (camada.ativo) onChange();
  }, [camada]);

  useEffect(
    () => () => {
      dispatch(limparCamadas());
    },
    []
  );

  return (
    <Checkbox
      size="sm"
      w="full"
      isChecked={!!thisLayer}
      onChange={onChange}
      pl={6}
      py={2}
      isTruncated
      borderRadius="md"
      _hover={{ bgColor: hoverBgColor }}
    >
      <Flex alignItems="center">
        <MapTypeIcon
          camadaType={camada.tipoServico.value}
          color="gray.500"
          mr="2"
        />
        {camada.nome}
      </Flex>
    </Checkbox>
  );
};
