/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Skeleton, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type Props = {
  url: string;
};

type Category = {
  nome: string;
  legends: Legend[];
};

type Legend = {
  image: string;
  label: string;
};

const MAPSERVER = "mapserver";
export const EsriLegend = ({ url }: Props) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const index = url.toLowerCase().indexOf(MAPSERVER);
  const urlBase = url.substring(0, index + MAPSERVER.length);
  const layerIndex = url
    .substring(index + MAPSERVER.length, url.length)
    .replaceAll("/", "");
  const urlLegend = `${urlBase}/legend?f=pjson`;

  useEffect(() => {
    async function getLegend() {
      try {
        setIsLoading(true);
        const response = await fetch(urlLegend);
        const data = await response.json();
        const newCategories: Category[] = [];

        data.layers.forEach((x: any) => {
          if (x.layerId != layerIndex) return;

          const category: Category = {
            nome: x.layerName,
            legends: x.legend.map((element: any) => {
              return {
                image: element.imageData,
                label: element.label
              };
            })
          };

          newCategories.push(category);
        });
        setCategories(newCategories);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    getLegend();
  }, [urlLegend, layerIndex]);

  if (isLoading) {
    return (
      <VStack w="full">
        <Skeleton h="30px" w="full" />
      </VStack>
    );
  }

  return (
    <VStack>
      {categories.map((c) => (
        <Box key={c.nome}>
          <Text fontWeight="medium">{c.nome}</Text>
          {c.legends.map((l) => (
            <Flex key={l.image} w="full" alignItems="center">
              <img src={`data:image/png;base64, ${l.image}`} />
              <Text pl="2" color="gray.800">
                {l.label}
              </Text>
            </Flex>
          ))}
        </Box>
      ))}
    </VStack>
  );
};
