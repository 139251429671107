import {
  Box,
  Flex,
  Heading,
  Progress,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { TextGray } from "../../../components/Atomos/Texts/TextGray";
import { userManager } from "../../../_config/userManager";

export const Login: React.FC = () => {
  const { state } = useLocation();

  useEffect(() => {
    async function signin() {
      await userManager.signinRedirect({ state: state });
    }
    signin();
  }, []);

  return (
    <Box
      flex="1"
      h="full"
      bgGradient={useColorModeValue(
        "linear(to-br, white 0%, gray.200 25%, white 75%)",
        "linear(to-br, gray.900 0%, gray.800 25%, gray.900 75%)"
      )}
    >
      <Box w="full">
        <Progress size="xs" isIndeterminate />
      </Box>
      <Flex
        maxH="400px"
        w="full"
        textAlign="center"
        direction="column"
        alignItems="center"
        py={10}
        px={8}
      >
        <Heading as="h2" textAlign="center" size="xl" mt={10} mb={4}>
          Redirecionando para o login
        </Heading>
        <TextGray>
          Aguarde enquanto estamos te redirecionando para a página de login.
        </TextGray>
      </Flex>
    </Box>
  );
};
