import { useEffect } from "react";
import { LatLngLiteral } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import { AspectRatio, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GeoJSON } from "react-leaflet";
import { GeoJsonObject } from "geojson";
import L from "leaflet";

import { RotatedMarker } from "./RotatedMarker";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterGeometriaFotos360 } from "store/modules/mapa/panorama/actions/obterGeometriaFotos360";

type Props = {
  position: LatLngLiteral;
  rotationAngle: number;
};

export const MiniMap = ({ position, rotationAngle }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { successPayload, requestNumber } = useTypedSelector(
    (state) => state.mapa.panorama.obterGeometriaFotos360
  );

  useEffect(() => {
    if (!successPayload) {
      dispatch(obterGeometriaFotos360());
    }
  }, [successPayload]);

  return (
    <AspectRatio
      position="absolute"
      right={{ base: "initial", xl: "4" }}
      left={{ base: "0", md: "4", xl: "initial" }}
      bottom={{ base: "initial", xl: "3" }}
      top={{ base: "10", xl: "initial" }}
      w="min(500px, 100vw)"
      ratio={16 / 9}
      zIndex={1}
    >
      <Box
        sx={{
          ".leaflet-container": {
            borderRadius: "md"
          }
        }}
        boxShadow="md"
      >
        <MapContainer
          center={position}
          zoom={18}
          style={{ height: "100%", width: "100%" }}
          zoomControl={false}
          preferCanvas={true}
          dragging={false}
          doubleClickZoom={false}
          touchZoom={false}
          scrollWheelZoom={false}
        >
          <GeoJSON
            key={requestNumber}
            eventHandlers={{
              click: (f) => {
                const id = f.layer.feature.id;
                navigate(`/mapa/panoramica/${id}/visualizacao`);
              }
            }}
            pointToLayer={(feature, latlng) => {
              return L.circleMarker(latlng, { radius: 2 });
            }}
            data={successPayload as GeoJsonObject}
          />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <RotatedMarker rotationAngle={rotationAngle} position={position} />
        </MapContainer>
      </Box>
    </AspectRatio>
  );
};
