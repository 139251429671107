import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";
import React from "react";
import { RiLogoutBoxLine, RiUserLine } from "react-icons/ri";
import { useTypedSelector } from "../../../store/hooks";
import { userManager } from "../../../_config/userManager";

type Props = {
  showName?: boolean;
};

export const UserDescription = ({ showName }: Props) => {
  const { user } = useTypedSelector((state) => state.oidc);
  const { successPayload } = useTypedSelector(
    (state) => state.configuracao.obterConfiguracao
  );

  const signOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    async function logout() {
      await userManager.signoutRedirect({ id_token_hint: user?.id_token });
    }

    logout();
  };

  if (!user) {
    return (
      <a href={process.env.REACT_APP_FRONTEND_INTERNAL || "/"}>
        <Button
          variant="outline"
          size="sm"
          colorScheme={successPayload?.corTema.nome}
        >
          Entrar
        </Button>
      </a>
    );
  }

  return (
    <Flex ml="6">
      <Menu strategy="fixed" isLazy>
        <MenuButton>
          <Flex alignItems="center">
            <Avatar
              size={"sm"}
              bg={`${successPayload?.corTema.nome}.500`}
              color="gray.50"
              name={user?.profile?.name?.toUpperCase()}
              mr={2}
              opacity={0.6}
            />
            {showName && (
              <Text
                color={"gray.600"}
                textAlign="left"
                fontSize="sm"
                fontWeight="medium"
                maxW="140px"
                isTruncated
              >
                {user?.profile?.name?.split(" ").slice(0, 2).join(" ")}
              </Text>
            )}
          </Flex>
        </MenuButton>
        <MenuList py="2">
          <MenuItem
            onClick={() =>
              window.open(process.env.REACT_APP_AUTHORITY, "_blank")
            }
            icon={<RiUserLine fontSize="1.1rem" />}
            py="2"
          >
            Gerenciar perfil
          </MenuItem>
          <MenuItem
            onClick={signOut}
            icon={<RiLogoutBoxLine fontSize="1.1rem" />}
            py="2"
          >
            Sair
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};
