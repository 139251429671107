import { Image } from "@chakra-ui/react";

export const Banner = () => {
  const imagemLogin = `${process.env.REACT_APP_API}api/v1/Customizacao/imagem-login`;

  return (
    <Image
      src={imagemLogin}
      alt="Imagem da Home Page"
      borderRadius="2xl"
      zIndex="0"
    />
  );
};
