import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import api from "../../../_config/api";
import {
  obterUsuario,
  obterUsuarioFailed,
  obterUsuarioSuccess
} from "./actions/obterUsuario";

function* obterUsuarioInfo() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.post,
      "api/v1/autenticacao"
    );

    yield put(obterUsuarioSuccess(response.data));
  } catch (error) {
    yield put(obterUsuarioFailed([]));
  }
}

export default all([takeLatest(obterUsuario.type, obterUsuarioInfo)]);
