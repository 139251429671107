/* eslint-disable @typescript-eslint/no-explicit-any */
import L from "leaflet";
import { store } from "../../store";
import { TipoServicoCamada } from "../../store/enums/tipoServicoCamada";
import { removerCamada } from "../../store/modules/mapa/camada/actions/selecionarCamada";
import { addEsriService } from "./servicesType/esri";
import { addImageEsri } from "./servicesType/imageEsri";
import { addWms } from "./servicesType/wms";

type AddLayerProps = {
  id: string;
  map: L.Map;
  tipoServico: TipoServicoCamada;
  url: string;
  pane: string;
  zIndex: number;
  opacity: number;
};

export const addLayerToMap = ({
  id,
  map,
  tipoServico,
  url,
  pane,
  zIndex,
  opacity
}: AddLayerProps) => {
  const layerAlreadyAdded = getLayerMap(id, map);

  if (layerAlreadyAdded) return;

  switch (tipoServico) {
    case TipoServicoCamada.WMS: {
      addWms({
        id,
        map,
        url,
        pane,
        zIndex,
        opacity
      });

      break;
    }
    case TipoServicoCamada.ESRI_REST: {
      addEsriService({
        id,
        map,
        url,
        pane,
        zIndex,
        opacity
      });
      break;
    }
    case TipoServicoCamada.IMAGEM: {
      addImageEsri({
        id,
        map,
        url,
        pane,
        zIndex,
        opacity
      });
      break;
    }
  }
};

export const removeLayerMap = (id: string, map: L.Map) => {
  store.dispatch(removerCamada({ id }));
  const layer = getLayerMap(id, map);
  if (layer) {
    layer.remove();
  }
};

export const updateLayerMap = (
  id: string,
  map: L.Map,
  tipoServico: TipoServicoCamada,
  opacity: number,
  zIndex: number
) => {
  const layer: any = getLayerMap(id, map);
  let options: AddLayerProps | null = null;
  if (tipoServico == TipoServicoCamada.ESRI_REST) {
    options = {
      id: layer.id,
      map,
      tipoServico: tipoServico,
      url: layer.options.url,
      pane: layer.options.pane,
      zIndex: zIndex,
      opacity: opacity
    };
  } else if (tipoServico == TipoServicoCamada.WMS) {
    options = {
      id: layer.id,
      map,
      tipoServico: tipoServico,
      url: layer._url,
      pane: layer.options.pane,
      zIndex: zIndex,
      opacity: opacity
    };
  } else if (tipoServico == TipoServicoCamada.IMAGEM) {
    options = {
      id: layer.id,
      map,
      tipoServico: tipoServico,
      url: layer.options.url,
      pane: layer.options.pane,
      zIndex: zIndex,
      opacity: opacity
    };
  }

  if (options) {
    layer.remove();
    addLayerToMap(options);
  }
};

export const getLayerMap = (id: string, map: L.Map): L.Layer | null => {
  let result = null;
  map.eachLayer((l) => {
    const layer: any = l;
    if (layer.id == id) {
      result = layer;
      return false;
    }
  });
  return result;
};
